<template>
	<div>
		<b-card no-body class="p-1">
			<div class="mb-2">
				<!-- Paginator -->
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
						>
							<span class="text-muted">
								Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>

				<b-row>
					<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<!-- <feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" /> -->
					</b-col>

					<b-col cols="12" xl="8" class="mt-1">
						<div class="container-btns-page-specimens d-flex justify-content-end">
							<div class="d-flex align-items-center">
								<b-form-group label="Fecha de inicio" class="input-alias-add ml-1">
									<b-form-datepicker
										placeholder="fecha de inicio"
										locale="es"
										:date-format-options="{}"
										v-model="dateInitial"
										class="datepikerAcces"
									></b-form-datepicker>
								</b-form-group>

								<b-form-group label="Fecha final" class="input-alias-add ml-1">
									<b-form-datepicker
										placeholder="fecha final "
										locale="es"
										:date-format-options="{}"
										v-model="dateFinish"
										class="datepikerAcces"
									></b-form-datepicker>
								</b-form-group>

								<b-button class="btn btn-primary ml-1 mt-1 btndate" @click="refreshTable">
									Buscar
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="table-responsive">
				<b-table
					:items="myProvider"
					:fields="fields"
					sticky-header="40vh"
					primary-key="id"
					:current-page="current_page"
					:per-page="perpage"
					responsive
					small
					show-empty
					ref="table-accessHistory"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(name)="data">
						<div class="d-flex d-code">
							<span>{{ data.item.name }}</span>
						</div>
					</template>
					<template #cell(created_at)="data">
						<div class="d-flex d-code">
							<span>{{ data.item.created_at | myGlobalDayWithHour }}</span>
						</div>
					</template>
					<template #cell(client_ip)="data">
						<div class="d-flex d-code">
							<span>{{ data.item.client_ip }}</span>
						</div>
					</template>
				</b-table>
			</div>
		</b-card>
	</div>
</template>

<script>
import Fields from "@/views/amg/users/accessHistory/data/fields.data.js"
import ServiceAccess from "@/views/amg/users/accessHistory/services/access.service.js"
export default {
	props: {
		idUser: {
			required: true,
		},
	},

	data() {
		return {
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			fields: Fields,
			dateFinish: null,
			dateInitial: null,
		}
	},

	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				page: ctx.currentPage,
				perpage: ctx.perPage,
				start_date: this.dateInitial,
				end_date: this.dateFinish,
			}
			try {
				const { data } = await ServiceAccess.getAllAccess(this.idUser, params)
				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page

				return data.data
			} catch (error) {
				console.log(error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		refreshTable() {
			this.$refs["table-accessHistory"].refresh()
		},
	},
}
</script>

<style scoped>
.d-code {
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 1rem;
}
.btndate {
	background-color: #7367f0 !important;
}
.datepikerAcces {
	width: 16rem;
}
</style>
