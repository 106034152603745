export default [
    {
        key:"name",
        label:"Usuario",
        visible:true  ,
        thClass:"text-center",

    },
    {
        key:"created_at",
        label:"Fecha de inicio de sesión",
        visible:true,        
        thClass:"text-center",

    },
    {
        key:"client_ip",
        label:"IP",
        visible:true ,
        thClass:"text-center",
  
    },
]