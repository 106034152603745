<template>
	<div>
		<b-media class="mb-2">
			<template #aside>
				<b-avatar ref="previewEl" :src="userObject.avatar" :text="avatarText(null)" size="90px" rounded />
			</template>
			<h4 class="mb-1">
				{{ $route.params.user_id ? "Actualizar" : "Crear usuario" }}
			</h4>
			<div class="d-flex flex-wrap">
				<b-button variant="primary" @click="$refs.fileInput.click()">
					<input ref="fileInput" type="file" @input="pickFile" class="d-none" />
					<span class="d-none d-sm-inline">Actualizar</span>
					<feather-icon icon="EditIcon" class="d-inline d-sm-none" />
				</b-button>
				<b-button variant="outline-secondary" class="ml-1" @click="userObject.avatar = ''">
					<span class="d-none d-sm-inline">Eliminar</span>
					<feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
				</b-button>
			</div>
		</b-media>
		<validation-observer ref="form">
			<b-form>
				<b-row>
					<b-col cols="12" md="4">
						<b-form-group label="Nombres" label-for="nombres">
							<validation-provider rules="required" v-slot="{ errors, valid }">
								<b-form-input
									id="nombres"
									v-model="userObject.name"
									:state="errors[0] ? false : valid ? true : null"
								/>
								<span style="color: red">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="4">
						<b-form-group label="Apellido" label-for="apellido">
							<b-form-input id="apellido" v-model="userObject.last_name" />
						</b-form-group>
					</b-col>
					<b-col cols="12" md="4">
						<b-form-group label="Telefono" label-for="telefono">
							<validation-provider rules="tel" v-slot="{ errors }">
								<b-form-input
									id="telefono"
									v-model="userObject.phone"
									v-mask="'#########'"
									:class="errors[0] ? 'is-invalid' : !errors[0] && userObject.phone ? 'is-valid' : ''"
								/>
								<span style="color: red">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="4">
						<b-form-group label="Correo" label-for="correo">
							<validation-provider rules="required|email" v-slot="{ errors, valid }">
								<b-form-input
									id="correo"
									type="email"
									v-model="userObject.user_email"
									:state="errors[0] ? false : valid ? true : null"
								/>
								<span style="color: red">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="4">
						<b-form-group label="Contraseña" label-for="password">
							<validation-provider rules="required" v-slot="{ errors, valid }">
								<b-form-input
									id="password"
									v-model="userObject.password"
									type="password"
									:state="errors[0] ? false : valid ? true : null"
								/>
								<span style="color: red">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="4"></b-col>
					<b-col cols="6" md="2" v-if="isAdmin">
						<validation-provider rules="required" v-slot="{ errors, valid }">
							<b-form-group label="Rol" class="mb-0">
								<b-form-radio-group
									v-model="rol"
									:options="optionsRoles"
									class="mb-0"
									value-field="item"
									text-field="name"
									disabled-field="notEnabled"
								></b-form-radio-group>
							</b-form-group>
							<span style="color: red">{{ errors[0] }}</span>
						</validation-provider>
					</b-col>
					<b-col cols="6" md="2" v-if="rol == 2 && isAdmin">
						<b-form-group label="Rol(es)">
							<v-select
								taggable
								multiple
								v-model="userObject.u_roles"
								:clearable="false"
								label="name"
								:options="arrayRoles"
								:reduce="(option) => option"
							/>
						</b-form-group>
					</b-col>

					<!-- <p>p {{userObject.u_roles}}</p> -->
					<b-col cols="6" md="2" v-if="rol == 2 && isAdmin && userObject.u_roles.length > 0">
						<b-form-group label="Cargo">
							<v-select
								taggable
								v-model="userObject.privilege_id"
								:clearable="false"
								label="name"
								:options="arrayCargo"
								:reduce="(option) => option.id"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12" md="4" v-if="isAdmin">
						<b-form-group label="Estado">
							<b-form-radio-group
								v-model="userObject.status"
								:options="optionsStatus"
								class="mb-3"
								value-field="item"
								text-field="name"
								disabled-field="notEnabled"
							></b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>

			<b-button
				variant="primary"
				class="mb-1 mb-sm-0 mr-0 mr-sm-1"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'"
				@click="onSubmit"
				v-if="!show"
			>
				Guardar
			</b-button>
			<b-button variant="primary" disabled class="mb-1 mb-sm-0 mr-0 mr-sm-1" v-else>
				<b-spinner small />
				Cargando...
			</b-button>
			<b-button
				variant="outline-secondary"
				type="reset"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'"
				@click="resetUserObject"
				v-if="!$route.params.user_id"
			>
				Resetear
			</b-button>
		</validation-observer>
	</div>
</template>

<script>
import { avatarText } from "@core/utils/filter"
import vSelect from "vue-select"
import { mask } from "vue-the-mask"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import commonsService from "@/services/commons.service"

export default {
	async mounted() {
		await this.getRoles()
		await this.getCargosInit()
		if (this.$route.params.user_id) {
			axios.get(`/api/users/edit/${this.$route.params.user_id}`).then((response) => {
				this.userObject = response.data[0]
				const result = this.userObject.u_roles.filter((rol) => rol.id != 1)
				if (result.length > 0) {
					this.rol = 2
				} else {
					this.rol = 1
				}
			})
		}

		// Accede al ID desde la ruta actual
		// const userId = this.$route.params.user_id
	 

		// Ahora puedes utilizar el ID como lo necesites
		// ...
	},
	computed: {
		layout() {
			return this.$store.getters["appConfig/layout"]
		},
		roles_comp() {
			if (this.currentUser) {
				return this.currentUser.role
			} else {
				return []
			}
		},
	},
	directives: { mask },
	data() {
		return {
			show: false,
			optionsRoles: [
				{ item: "1", name: "Dueño" },
				{ item: "2", name: "Otro" },
			],
			optionsStatus: [
				{ item: "1", name: "Activo" },
				{ item: "0", name: "Inactivo" },
			],
			arrayRoles: [],
			arrayCargo: [{ id: null, name: "Sin cargo" }],
			rol: null,
			userObject: {
				iduser: this.$route.params.user_id,
				avatar: "",
				name: null,
				last_name: null,
				phone: null,
				user_email: null,
				password: null,
				u_roles: [],
				status: 1,
				file_name: null,
				original_avatar: null,
				privilege_id: null,
			},
			show: false,
		}
	},
	components: {
		vSelect,
	},
	methods: {
		avatarText: function () {
			return avatarText()
		},
		getRoles() {
			axios.post("/api/get-role").then((res) => {
				this.arrayRoles = res.data
			})
		},
		async getCargosInit() {
			const { data } = await commonsService.getCargos()
			this.arrayCargo = [...this.arrayCargo, ...data]
			console.log("sd", this.arrayCargo)
		},
		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files
			if (file && file[0]) {
				let reader = new FileReader()
				reader.onload = (e) => {
					this.userObject.avatar = e.target.result
				}
				reader.readAsDataURL(file[0])
				this.$emit("input", file[0])
				this.userObject.file_name = file[0].name
			}
		},
		showToast(variant, position) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title: `${this.$route.params.user_id ? "Usuario actualizado" : "Usuario creado"}`,
						icon: "InfoIcon",
						text: `${
							this.$route.params.user_id
								? "El usuario ha sido actualizado correctamente."
								: "El usuario ha sido creado correctamente."
						}`,
						variant,
					},
				},
				{
					position,
				}
			)
		},
		onSubmit() {
			this.$refs.form.validate().then((confirm) => {
				if (!confirm) {
					return
				}
				this.show = true
				axios
					.post("/api/save-update-user", {
						iduser: this.currentUser.user_id,
						name: this.userObject.name,
						last_name: this.userObject.last_name,
						phone: this.userObject.phone,
						useremail: this.userObject.user_email,
						password: this.userObject.password,
						roles: this.userObject.u_roles,
						status: this.userObject.status,
						t_update: this.$route.params.user_id,
						file_image: this.userObject.avatar,
						file_name: this.userObject.file_name ? this.userObject.file_name : null,
						privilege_id: this.userObject.privilege_id ? this.userObject.privilege_id : null,
					})
					.then((response) => {
						if (response.status == 200) {
							this.showToast("success", "top-right")
							this.show = false
							this.$router.push("/users-route")
						}
					})
					.catch((err) => {
						console.log(err)
					})
			})
		},
		resetUserObject() {
			this.userObject.avatar = ""
			this.userObject.name = null
			this.userObject.last_name = null
			this.userObject.phone = null
			this.userObject.user_email = null
			this.userObject.password = null
			this.userObject.u_roles = []
			this.userObject.status = 1
			this.$refs.form.reset()
		},
	},
	watch: {
		rol(newVal, oldVal) {
			if (!this.$route.params.user_id) {
				if (newVal == 1) {
					this.userObject.u_roles = []
					this.userObject.u_roles.push({
						id: 1,
					})
				} else {
					this.userObject.u_roles = []
				}
			}
		},
	},
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
