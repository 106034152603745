import Vue from "vue";

const url = "/api";

class CommonsService {
	async getCargos() {
		try {
			return await Vue.axios.get(`${url}/get-privileges`);
		} catch (err) {
			console.error(err);
		}
	}
}
export default new CommonsService();
