import axios from "@/axios"

const url = "api/get-user-access/";



class AccessService {
    async getAllAccess(id, params){
        try {
            const data = await axios.get(`${url}${id}`,{params});
            return data
        } catch (error) {
            throw error
        }

    }
}

export default new AccessService()